import AOS from "aos";
import "./../images/site-logo-header.png";
import "./../images/site-logo-footer.png";
import './../images/atencion-personalizada.png';
import './../images/compra-segura.png';
import './../images/envios-todo-el-pais.png';
import './../images/todas-las-tarjetas.png';

$(function () {
    submit_forms_need_validation();
    click_handheld_footer_bar_link_search();
    submit_form_enviar_consulta();
    hidden_form_page_contacto_modal_success();
	create_paroller();
	click_links_preguntas();
	// init AOS
	AOS.init();    
});

function submit_forms_need_validation() {
	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.getElementsByClassName('needs-validation');
	// Loop over them and prevent submission
	Array.prototype.filter.call(forms, function (form) {
		form.addEventListener(
			'submit',
			function(event) {
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			},
			false
		);
	});
}

function click_handheld_footer_bar_link_search() {
    $('.handheld-footer-bar .search a').on('click', function (e) {
        e.preventDefault();
        var $site_search = $('.handheld-footer-bar .site-search');
        if ($site_search.hasClass('active')) {
            $site_search.removeClass('active');
        } else {
            $site_search.addClass('active');
        }
    });
}

function submit_form_enviar_consulta() {
	var form = document.getElementById('form-page-contacto');
	if (form) {
		form.addEventListener(
			'submit',
			function(event) {
                if (event.defaultPrevented) {
                    return;
                }			
                event.preventDefault();
				event.stopPropagation();				
				var $submit = $(form).find('[type="submit"]');
				$submit.prop('disabled', true).children('.send').addClass('d-none').next().removeClass('d-none');
                $(form).resetAppearanceClientSideValidation().resetAppearanceServerSideValidation();
                var params = $(form).serializeObject();
                params.nonce = wp_ajax.nonce.enviarConsulta;
                params.action = $(form).attr('action');
                $.post(wp_ajax.url, params, function (response) {
                    $('#form-page-contacto-modal-success').modal();
                }, 'json').fail(function (jqxhr) {
					if (typeof jqxhr.responseJSON === 'object' && jqxhr.responseJSON.errors.length > 0) {
						$(form).find('[name]').each(function (i, elem) {
							if ($.inArray($(elem).attr('name'), jqxhr.responseJSON.errors) > -1) {
								$(elem).addClass('is-invalid');
							} else {
								$(elem).addClass('is-valid');
							}
						});
						return;
					}
					$('#form-page-contacto-modal-danger').modal();
                }).always(function () {
					$submit.prop('disabled', false).children('.send').removeClass('d-none').next().addClass('d-none');
				});
			}
		);
	}
}

function hidden_form_page_contacto_modal_success() {
    var $modal = $('#form-page-contacto-modal-success');
    if ($modal.length > 0) {
        $modal.on('hidden.bs.modal', function (e) {
            $('#form-page-contacto').resetAppearanceClientSideValidation().resetAppearanceServerSideValidation().trigger('reset');
        });
    }
}

function create_paroller() {
    var $paroller = $('.paroller');
    if ($paroller.length > 0) {
        $paroller.paroller();
    }
}

function click_links_preguntas() {
	var $links = $('.preguntas-link-group > a');
	if ($links.length > 0) {
		$links.on('click', function (e) {
			e.preventDefault();
			$links.attr('data-active', 'false');
			$('.detalles > .detalle').attr('data-show', 'false');
			$(this).attr('data-active', 'true');
			var $detalle = $($(this).attr('data-target'));
			$detalle.attr('data-show', 'true');
		});
	}
}
